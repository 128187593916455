import React, { useEffect } from 'react';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
} from 'antd';
// Hooks
import { useTranslation } from 'react-i18next';
// Services
import APIService from '@/services/API';
// Interfaces
import { Agent } from '@/interfaces/agent.interface';
import { useQuery } from 'react-query';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

export interface AgentFormProps {
  formRef: FormInstance<Agent>;
  initialValues?: Record<string, string | number>;
  onlyView?: boolean;
  profile?: string;
  onSubmit?: (values: Agent) => Promise<void>;
}
let timeout: NodeJS.Timeout;

const AgentForm: React.FC<AgentFormProps> = ({
  formRef,
  onSubmit,
  initialValues,
  onlyView,
  profile,
}) => {
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const [userParams, setUserParams] = React.useState<Record<string, string>>({
    $skip: '0',
    $limit: '50',
  });
  const { data: users, isLoading: loadingUsers } = useQuery(['users', userParams], () =>
    APIService.getUsers(userParams),
  );
  useEffect(() => {
    if (initialValues?.user_id) {
      setUserParams((prev) => ({
        ...prev,
        _id: initialValues.user_id.toString(),
      }));
    } else {
      setUserParams({ $skip: '0', $limit: '50' });
    }
  }, [initialValues]);

  return (
    <Form
      name="agent"
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      disabled={onlyView}
      initialValues={{
        biography: {
          en: '',
          es: '',
        },
        title: {
          en: '',
          es: '',
        },
        schedule: {
          en: '',
          es: '',
        },
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={4} style={{ textAlign: 'center' }}>
          <Avatar src={profile} size={120} icon={<UserOutlined />} />
        </Col>
        <Col xs={24} sm={10} md={8} lg={6}>
          <Form.Item
            label={t('users.user')}
            name="user_id"
            shouldUpdate
            rules={[{ required: true, type: 'string', whitespace: true }]}
            extra={
              <>
                <span style={{ fontSize: '12px' }}>{t('agents.userAgentList')}. </span>
                <Button
                  type="link"
                  onClick={() => nav('/dashboard/users')}
                  style={{
                    fontSize: '12px',
                    height: 'auto',
                    lineHeight: 1,
                    padding: 0,
                  }}
                >
                  {t('agents.newAgent')}
                </Button>
              </>
            }
          >
            <Select
              loading={loadingUsers}
              notFoundContent={false}
              showSearch
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={false}
              onSearch={(value) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setUserParams({
                    $skip: '0',
                    $limit: '10',
                    'full_name[$regex]': value,
                    'full_name[$options]': 'i',
                  });
                }, 500);
              }}
              options={users?.data.map((user) => {
                return {
                  label: user.full_name,
                  value: user._id,
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={14} md={8} lg={8}>
          <Tabs
            size="small"
            tabPosition="left"
            defaultActiveKey={i18n.language}
            items={[
              {
                key: 'es',
                label: 'ES',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('agents.titleForm')}
                    name={['title', 'es']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Licenciatura" />
                  </Form.Item>
                ),
              },
              {
                key: 'en',
                label: 'EN',
                forceRender: true,
                children: (
                  <Form.Item
                    label={t('agents.titleForm')}
                    name={['title', 'en']}
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Degree" />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Col>
        <Col xs={12} sm={8} md={8} lg={5}>
          <Form.Item
            label={t('users.cellphone')}
            name="cellphone"
            rules={[
              {
                max: 18,
                min: 10,
                required: true,
                type: 'string',
                whitespace: true,
              },
              {
                validator: (rule, value, callback) => {
                  // Expresión regular para validar un número de teléfono
                  const phoneRegex = /^[0-9]{10,18}$/;

                  if (value && !phoneRegex.test(value)) {
                    callback(
                      'El número de teléfono no es válido. Debe contener solo números y tener entre 10 y 18 dígitos.',
                    );
                  } else {
                    callback(); // Llamada sin argumentos indica que la validación es exitosa
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={12} sm={8} md={6} lg={5}>
          <Form.Item
            label={t('users.phone')}
            name="phone"
            rules={[
              {
                max: 18,
                min: 10,
                required: false,
                type: 'string',
                whitespace: true,
              },
              {
                validator: (rule, value, callback) => {
                  // Expresión regular para validar un número de teléfono
                  const phoneRegex = /^[0-9]{10,18}$/;

                  if (value && !phoneRegex.test(value)) {
                    callback(
                      'El número de teléfono no es válido. Debe contener solo números y tener entre 10 y 18 dígitos.',
                    );
                  } else {
                    callback(); // Llamada sin argumentos indica que la validación es exitosa
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} sm={8} md={6} lg={6}>
          <Form.Item
            label={t('agents.license')}
            name="license"
            rules={[
              {
                required: false,
                type: 'string',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} sm={8} md={6} lg={6}>
          <Form.Item
            label={t('users.birthday')}
            name="birthday"
            rules={[{ required: false, type: 'date' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={8} sm={6} md={6} lg={6}>
          <Form.Item
            label={t('agents.experience')}
            name="experience"
            rules={[
              {
                required: false,
                type: 'number',
              },
            ]}
          >
            <InputNumber placeholder="10" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={16} sm={10} md={10} lg={8}>
          <Tabs
            size="small"
            tabPosition="left"
            items={[
              {
                key: 'es',
                forceRender: true,
                label: 'ES',
                children: (
                  <Form.Item
                    label={t('agents.schedule')}
                    name={['schedule', 'es']}
                    rules={[
                      {
                        required: false,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="L-V de 7 a.m. a 18 p.m." />
                  </Form.Item>
                ),
              },
              {
                key: 'en',
                forceRender: true,
                label: 'EN',
                children: (
                  <Form.Item
                    label={t('agents.schedule')}
                    name={['schedule', 'en']}
                    rules={[
                      {
                        required: false,
                        type: 'string',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="M-F, 7 a.m. a 18 p.m." />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Col>

        <Col span={24}>
          <Divider>Social</Divider>
          <Form.List name="socials">
            {(fields, { add, remove }) => (
              <>
                <Row justify="start" gutter={[10, 10]}>
                  {fields.map((field, id) => (
                    <Col key={field.key} xs={24} md={12}>
                      <Row gutter={[5, 5]} style={{ position: 'relative' }}>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label={t('general.socials')}
                            name={[field.name, 'slug']}
                            rules={[{ required: true }]}
                          >
                            <Select style={{ width: '100%' }}>
                              <Select.Option value="whatsapp">Whatsapp</Select.Option>
                              <Select.Option value="phone">Phone</Select.Option>
                              <Select.Option value="cellphone">Cellphone</Select.Option>
                              <Select.Option value="email">Email</Select.Option>
                              <Select.Option value="facebook">Facebook</Select.Option>
                              <Select.Option value="thread">Thread</Select.Option>
                              <Select.Option value="instagram">Instagram</Select.Option>
                              <Select.Option value="linkedin">Linkedin</Select.Option>
                              <Select.Option value="youtube">Youtube</Select.Option>
                              <Select.Option value="tiktok">Tiktok</Select.Option>
                              <Select.Option value="discord">Discord</Select.Option>
                              <Select.Option value="twitch">Twitch</Select.Option>
                              <Select.Option value="telegram">Telegram</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            label={t('general.type')}
                            name={[field.name, 'type']}
                            rules={[{ required: true }]}
                          >
                            <Select style={{ width: '100%' }}>
                              <Select.Option value="social">
                                {t('general.socialNet')}
                              </Select.Option>
                              <Select.Option value="cellphone">
                                {t('users.cellphone')}
                              </Select.Option>
                              <Select.Option value="email">
                                {t('users.email')}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label={t('general.content')}
                            name={[field.name, 'value']}
                            rules={[{ required: true }]}
                          >
                            <Input style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <MinusCircleOutlined
                          style={{ position: 'absolute', top: 8, right: 4 }}
                          onClick={() => remove(field.name)}
                        />
                      </Row>
                    </Col>
                  ))}
                </Row>

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('agents.addSocial')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24}>
          <Form.Item name={['biography', 'en']} noStyle>
            <Input style={{ display: 'none' }} />
          </Form.Item>
          <Form.Item name={['biography', 'es']} noStyle>
            <Input style={{ display: 'none' }} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {(form) => {
              return (
                <Tabs
                  size="small"
                  items={[
                    {
                      key: 'es',
                      label: 'ES',
                      children: (
                        <Form.Item label={t('agents.biography')}>
                          {onlyView ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: form.getFieldValue(['biography', 'es']),
                              }}
                            ></div>
                          ) : (
                            <JoditEditor
                              value={form.getFieldValue(['biography', 'es'])}
                              config={{ readonly: false, height: 300, tabIndex: 1 }}
                              onBlur={(content) =>
                                form.setFieldValue(['biography', 'es'], content)
                              }
                            />
                          )}
                        </Form.Item>
                      ),
                    },
                    {
                      key: 'en',
                      label: 'EN',
                      children: (
                        <Form.Item label={t('agents.biography')}>
                          {onlyView ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: form.getFieldValue(['biography', 'en']),
                              }}
                            ></div>
                          ) : (
                            <JoditEditor
                              value={form.getFieldValue(['biography', 'en'])}
                              config={{ readonly: false, height: 300, tabIndex: 1 }}
                              onBlur={(content) =>
                                form.setFieldValue(['biography', 'en'], content)
                              }
                            />
                          )}
                        </Form.Item>
                      ),
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AgentForm;
